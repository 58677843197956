import React,{ useState } from 'react'
import { Container, Row, Col } from 'react-bootstrap';
// import './Grid.css'

import ltb from '../../Assets/images/logos/little_traverse_bay.png';
import ojibwelogo from '../../Assets/images/logos/ojibwe_learning.png';
import tatalogo from '../../Assets/images/logos/tataskweyak.png';
import patwinlogo from '../../Assets/images/logos/patwin.png';
import shakopeelogo from '../../Assets/images/logos/shakopee.png';
import dehchologo from '../../Assets/images/logos/dfn.png';
import whitecaplogo from '../../Assets/images/logos/whitecap.png';
import bellalogo from '../../Assets/images/logos/bellabella.png';
import aetslogo from '../../Assets/images/logos/aets.png';
import sicangulogo from '../../Assets/images/logos/sicangu.png';
import sicangugamelogo from '../../Assets/images/logos/sicangu_game.png';
import whiteearthlogo from '../../Assets/images/logos/white_earth.png';
import whiteearthgamelogo from '../../Assets/images/logos/white_earth_game.png';
import prairieislandlogo from '../../Assets/images/logos/prairie_island.png';
import kwumutlelumlogo from '../../Assets/images/logos/kwumut_lelum.png';
import micmacslogo from '../../Assets/images/logos/micmacs.png';
import treatyplaylogo from '../../Assets/images/logos/treaty_play.png';
import aafslogo from '../../Assets/images/logos/aafs.png';
import ojibwaylogo from '../../Assets/images/logos/ojibway.png';

function Grid() {
    const images = [     
      // Game and Full Immersion Apps
        {src: patwinlogo, link: "https://apps.ogokilearning.com/yochadehewintun/"},
        {src: shakopeelogo, link: "https://apps.ogokilearning.com/shakopee/"},
        {src: ojibwelogo, link: "https://apps.ogokilearning.com/akrc/"},
        {src: whitecaplogo, link: "https://apps.ogokilearning.com/whitecap/"},
        {src: aafslogo, link: "https://apps.ogokilearning.com/AAFS/"},
        {src: sicangugamelogo, link: "https://apps.ogokilearning.com/sicangucogame/"},
        {src: whiteearthgamelogo, link: "https://apps.ogokilearning.com/whiteearthgame/"},

      // Universal Apps
        {src: kwumutlelumlogo, link: "https://apps.ogokilearning.com/kwumutlelum/"},
        {src: ltb, link: "https://apps.ogokilearning.com/littletraversebay/"},
        {src: tatalogo, link: "https://apps.ogokilearning.com/tataskweyak/"},
        {src: dehchologo, link: "https://apps.ogokilearning.com/dehcho/"},
        {src: bellalogo, link: "https://apps.ogokilearning.com/heiltsuk/"},
        {src: aetslogo, link: "https://apps.ogokilearning.com/aets/"},
        {src: ojibwaylogo, link: "https://apps.ogokilearning.com/ojibway/"}, 
        {src: prairieislandlogo, link: "https://apps.ogokilearning.com/prairie/"}, 
        {src: sicangulogo, link: "https://apps.ogokilearning.com/sicangu/"},
        // {src: whiteearthlogo, link: "https://apps.ogokilearning.com/whiteearth/"},
        // {src: micmacslogo, link: "https://apps.ogokilearning.com/micmacs/"},

        // Storybook
        {src: treatyplaylogo, link: "https://apps.ogokilearning.com/treatyplay/"},
      ];

      const [styles, setStyles] = useState(
        new Array(images.length).fill({ transition: 'transform 0.2s', width: '80%', height: 'auto', marginBottom:'15%',})
      );
    
      const handleMouseEnter = (index) => {
        const newStyles = [...styles];
        newStyles[index] = { transform: 'scale(1.1)', transition: 'transform 0.2s',width: '80%', height: 'auto',marginBottom:'15%', };
        setStyles(newStyles);
      };
    
      const handleMouseLeave = (index) => {
        const newStyles = [...styles];
        newStyles[index] = { transition: 'transform 0.2s',width: '80%', height: 'auto',marginBottom:'15%', };
        setStyles(newStyles);
      };
   

    return (
        <Container className='gridWrapper'>
        <Row>
            {images.map((img, i) => (
                <Col lg={4} md={4} sm={4} xs={6} key={i}>
                    <a href={img.link}>
                        <img 
                            src={img.src} 
                            alt="" 
                            style={styles[i]} 
                            onMouseEnter={() => handleMouseEnter(i)} 
                            onMouseLeave={() => handleMouseLeave(i)} 
                        />
                    </a>
                </Col>
            ))}
        </Row>
    </Container>
    )
}

export default Grid